import Component from "./Component";
import { SETTINGS } from "../Settings";


export default class Dropdown extends Component {
    constructor(checkout, name, description, error, values, section = undefined) {
        super(checkout, name, description, error, section);
        this._values = values;
    }

    validate = () => {
        if (document.getElementById(`${SETTINGS.nameDashed}-${this._section.name}-${this._name}`).value !== "") {
            this._checkout.error.remove(`${SETTINGS.nameDashed}-${this._section.name}-${this._name}`);
            return true;
        } else {
            this._checkout.error.add(this._error, `${SETTINGS.nameDashed}-${this._section.name}-${this._name}`);
            return false;
        }
    }

    create = () => {
        let id = `${SETTINGS.nameDashed}-${this._section.name}-${this._name}`;
        if (document.getElementById(id)) { return null; }
        let description = `${this._description}`;
        let select = document.createElement("select");
        select.id = id;
        select.name = `${this._section.name}[${this._name}]`;
        select.classList.add("gui-validate");
        let o = document.createElement("option");
        o.value = "";
        o.text = description;
        select.add(o);
        for (let option of Object.values(this._values)) {
            let o = document.createElement("option");
            o.value = option.id;
            o.text = option.label;
            if (window[SETTINGS.nameDashed] && window[SETTINGS.nameDashed][`${this._section.name}-${this._name}`] === option.id) {
                o.selected = true;
            } else
                if (Checkout.data.payment_method && Checkout.data.payment_method.data[this._name] + "" === option.id) {
                    o.selected = true;
                }
            select.add(o);
        }

        select.onchange = e => {
            if (window[SETTINGS.nameDashed]) {
                window[SETTINGS.nameDashed][`${this._section.name}-${this._name}`] = e.target.value;
            }
        };

        let label = document.createElement("label");
        label.htmlFor = id;
        label.innerHTML = `${description}: <em>*</em>`;

        let guiValue = document.createElement("div");
        guiValue.classList.add("gui-value");
        guiValue.text = description;

        let guiHandle = document.createElement("div");
        guiHandle.classList.add("gui-handle");

        let guiSelect = document.createElement("div");
        guiSelect.classList.add("gui-select");
        guiSelect.appendChild(guiValue);
        guiSelect.appendChild(guiHandle);
        guiSelect.appendChild(select);

        this._section.form.appendChild(this.createField(guiSelect));
    }
}
