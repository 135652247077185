import Component from "./Component";
import { SETTINGS } from "../Settings";

export default class RadioButtons extends Component {
    constructor(checkout, name, description, values, error, section = undefined) {
        super(checkout, name, description, error, section);
        this._values = values;
    }

    validate = () => {
        if (document.querySelector(`input[name=${this._section.name}\\[${this._name}\\]]:checked`)) {
            if (document.querySelector(`input[name=${this._section.name}\\[${this._name}\\]]:checked`).value !== "") {
                this._checkout.error.remove(`${this._section.name}-${this._name}`);
                return true
            }
        }

        this._checkout.error.add(this._error, `${this._section.name}-${this._name}`);
        return false;
    }

    create = () => {
        let radioContainer = document.createElement('div');
        let isSet = false;
        for (const value of this._values) {
            let id = `${SETTINGS.nameDashed}-${this._section.name}-${this._name}-${typeof (value) == "string" ? value : value["value"]}`;
            if (document.getElementById(id)) { return; }
            let radio = document.createElement('input');
            radio.setAttribute('type', 'radio');
            radio.value = typeof (value) == "string" ? value : value["value"];
            radio.id = id;
            radio.name = `${this._section.name}[${this._name}]`;
            radio.style.marginRight = "8px";
            radioContainer.appendChild(radio);

            let label = document.createElement('label');
            label.innerText = typeof (value) == "string" ? value : value["label"];
            label.setAttribute('for', typeof (value) == "string" ? value : value["value"]);
            radioContainer.appendChild(label);

            radio.onchange = e => {
                if (window[SETTINGS.nameDashed]) {
                    window[SETTINGS.nameDashed][`${this._section.name}-${this._name}`] = e.target.value;
                }
            };

            if (window[SETTINGS.nameDashed] && window[SETTINGS.nameDashed][`${this._section.name}-${this._name}`] === radio.value) {
                radio.checked = true;
                isSet = true;
            } else if (!isSet && Checkout.data.payment_method && Checkout.data.payment_method.data[this._name] + "" === radio.value) {
                radio.checked = true;
            }
        }

        this._section.form.appendChild(this.createField(radioContainer));
    }
}
