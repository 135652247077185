import PaymentComponent from "./PaymentComponent";

export default class MultiSafepayExtenstionDMWS {
    #providerName;
    #dmwsSettings;

    constructor(providerName) {
        this.#providerName = providerName;
        this.#dmwsSettings = window.dmws.settings.checkout
    }

    async format() {
        this.togglePaymentMethods();
        this.hideApplePay();
        await this.addIdealIssuers();
        await this.loadCreditCardComponent();
    }

    hideApplePay() {
        let applePayContainer = this.findCheckoutPaymentMethod('APPLEPAY');
        if (!applePayContainer) {
            return;
        }

        try {
            if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
                applePayContainer.style.display = "block";
            } else {
                applePayContainer.style.display = "none";
            }
        } catch (error) {
            console.debug('An error occurred while verifying if Apple Pay is available:', error);
            applePayContainer.style.removeProperty('display');
            applePayContainer.style.display = "none";
        }
    }

    async addIdealIssuers() {
        let idealData = Checkout?.payment_methods?.data[this.#providerName]?.methods["IDEAL"]?.data;
        if (!idealData) {
            return;
        }

        this.addIdealDropdown(idealData);
    }

    async loadIdealComponent(idealComponentData) {
        let checkoutIdeal = this.findCheckoutPaymentMethod("IDEAL");
        if (!checkoutIdeal) {
            return;
        }

        checkoutIdeal
            .querySelector('.gui-payment-methods')
            .insertAdjacentHTML('afterend', `<div id="gui-form-payment-method-${this.#providerName}-IDEAL-form"></div>`);

        await new PaymentComponent(this.#providerName, "IDEAL", idealComponentData).load();
    }

    addIdealDropdown(idealData) {
        let idealInput = document.querySelector(`label[for="gui-payment-${this.#providerName}-IDEAL"]`);
        let issuerIdData = idealData["fields"]["issuer_id"];
        if (!idealInput || !issuerIdData) {
            return;
        }

        let options = Object.values(issuerIdData.values)
            .map(issuer => { return `<option value="${issuer.id}">${issuer.label}</option>`; })
            .reduce((a, b) => a + b, '');

        let defaultIssuerText = issuerIdData?.label ?? 'Choose your bank';

        let checkoutSelect = `<div class="gui-payment-method-form">
                <label for="gui-payment-${this.#providerName}-ideal-issuer" class="hidden">
                    ${issuerIdData.label}
                </label>
                <div class="checkout-select">
                    <select id="gui-payment-${this.#providerName}-ideal-issuer" name="IDEAL[issuer_id]" class="gui-validate">
                        <option value="" selected="selected" disabled="disabled">${defaultIssuerText}</option>
                        ${options}
                    </select>
                </div>
            </div>`;

        idealInput.insertAdjacentHTML('afterend', checkoutSelect);
    }

    togglePaymentMethods() {
        let paymentMethodsObject = Checkout?.payment_methods?.data[this.#providerName]?.methods;
        if (!paymentMethodsObject) {
            return;
        }

        let paymentMethods = Object.values(paymentMethodsObject);
        let billingCountry = Checkout.billing_address.country;

        for (const paymentMethod of paymentMethods) {
            let countries = paymentMethod.data.countries;
            if (countries.length <= 0) {
                continue;
            }

            if (!countries.includes(billingCountry)) {
                let container = this.findCheckoutPaymentMethod(paymentMethod.id);
                container.style.display = "none";
            }
        }
    }

    async loadCreditCardComponent() {
        let creditcardData = Checkout?.payment_methods?.data[this.#providerName]?.methods["CREDITCARD"]?.data;
        if (!creditcardData) {
            return;
        }

        let creditcardComponentData = creditcardData.component;
        if (!creditcardComponentData || !creditcardComponentData.enabled) {
            return;
        }

        let checkoutCreditcard = this.findCheckoutPaymentMethod("CREDITCARD");
        if (!checkoutCreditcard) {
            return;
        }

        checkoutCreditcard
            .querySelector('.gui-payment-methods')
            .insertAdjacentHTML('afterend', `<div id="gui-form-payment-method-${this.#providerName}-CREDITCARD-form"></div>`);

        await new PaymentComponent(this.#providerName, "CREDITCARD", creditcardComponentData).load();
    }

    findCheckoutPaymentMethod(gateway) {
        if (!document.querySelector(`#gui-payment-${this.#providerName}-${gateway}`)) {
            return null;
        }

        let paymentMethods = document.querySelectorAll('.checkout-payment-method');
        for (const paymentMethod of paymentMethods) {
            if (paymentMethod.querySelector(`#gui-payment-${this.#providerName}-${gateway}`)) {
                return paymentMethod;
            }
        }

        return null;
    }
}
