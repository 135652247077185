import Component from './Component';
import {SETTINGS} from '../Settings';

export default class Payment extends Component {
    constructor(checkout, name, description, error, componentData, section = undefined) {
        super(checkout, name, description, error, section);
        this.componentData = componentData;
        this.data = [];
        this.component = undefined;
    }

    static canUseComponents = () => {
        return ['CREDITCARD'];
    }

    validate = () => {
        if (this.component === undefined) {
            this.component = window[`${SETTINGS.nameDashed}-${this._section.name}-${this._name}-component`];
        }

        if (this.component === undefined || this.component.hasErrors()) {
            return false;
        }

        return true;
    }

    create = async () => {
        if (!Payment.canUseComponents().includes(this._name)) {
            return null;
        }

        let id = `${SETTINGS.nameDashed}-${this._section.name}-${this._name}`;
        if (document.getElementById(id)) {
            return null;
        }

        let paymentData = document.createElement('input');
        paymentData.setAttribute('type', 'hidden');
        paymentData.id = id;
        paymentData.name = `${this._section.name}[payload]`;
        this._section.form.appendChild(paymentData);
        this.paymentData = paymentData;

        if (window[`${SETTINGS.nameDashed}-${this._section.name}-${this._name}-component`]) {
            let component = window[`${SETTINGS.nameDashed}-${this._section.name}-${this._name}-component`];
            if(!component.hasErrors()) {
                this.paymentData.value = component.getPaymentData()['payload']
            }
        }

        let container = this.createField(document.createElement('div'), false);
        container.id = `${SETTINGS.nameDashed}-${this._section.name}-${this._name}-container`;
        this._section.form.appendChild(container);
        await this.setup();
    }

    setup = async () => {
        if (this.component !== undefined) {
            return;
        }

        let checkoutData = window.Checkout.data;
        if(!checkoutData){
            checkoutData = window.Checkout;
        }
        let locale = checkoutData.billing_address.country

        const orderData = {
            currency: ('' + this.componentData['currency']).toUpperCase(),
            amount: Math.round(checkoutData.quote.totals.grand_total * 100),
            customer: {
                locale: locale,
                country: checkoutData.billing_address.country
            },
            template : {
                settings: {
                    embed_mode: true
                }
            }
        };

        if (!window[`${SETTINGS.nameDashed}-${this._section.name}-${this._name}-component`]) {
            this.component = new window.MultiSafepay({
                env: this.componentData['environment'],
                apiToken: this.componentData['token'],
                order: orderData
            });
            window[`${SETTINGS.nameDashed}-${this._section.name}-${this._name}-component`] = this.component;
        }


        let gateway = this._name;
        if (['DANKORT', 'POSTEPAY', 'CARTESBANCAIRES'].includes(gateway)) {
            gateway = 'VISA';
        }

        window[`${SETTINGS.nameDashed}-${this._section.name}-${this._name}-component`].init('payment', {
            container: `#${SETTINGS.nameDashed}-${this._section.name}-${this._name}-container`,
            gateway: this._name,
            onLoad: state => {},
            onError: state => {
                console.log('onError', state);
            },
            onValidation: state => {
                if (state['valid']) {
                    let input = document.querySelector(`input[name='${this._section.name}[payload]'`);
                    input.value = window[`${SETTINGS.nameDashed}-${this._section.name}-${this._name}-component`].getPaymentData()['payload'];
                }
            }
        });

    }

    static addScript = async () => {
        await new Promise((resolve, reject) => {
            if (document.getElementById('componentjs')) {
                resolve(true);
                return;
            }
            const script = document.createElement('script');
            script.async = true;
            script.defer = false;
            script.src = 'https://pay.multisafepay.com/sdk/components/v2/components.js';
            script.id = 'componentjs'

            document.head.appendChild(script);
            script.addEventListener('load', () => {
                resolve(true);
            });
        });
    }

    static addStyle = async () => {
        await new Promise((resolve, reject) => {
            if (document.getElementById('componentcss')) {
                resolve(true);
                return;
            }
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = 'https://pay.multisafepay.com/sdk/components/v2/components.css';
            link.id = 'componentcss';
            document.head.appendChild(link);
            resolve(true);
        });
    }
}
