export default class Component {
    constructor(checkout, name, description, error, section = undefined) {
        this._section = section;
        this._checkout = checkout;
        this._name = name;
        this._description = description;
        this._error = error;
    }

    validate = () => {
        return false;
    }

    createField = (input, createLabel = true) => {
        let guiField = document.createElement("div");
        guiField.classList.add("gui-field");
        let description = this._description;

        let guiValue = document.createElement("div");
        guiValue.classList.add("gui-value");
        guiValue.text = description;

        let guiHandle = document.createElement("div");
        guiHandle.classList.add("gui-handle");

        let guiInput = document.createElement("div");
        guiInput.classList.add("gui-field");
        guiInput.appendChild(guiValue);
        guiInput.appendChild(guiHandle);
        guiInput.appendChild(input);

        if (createLabel) {
            let label = document.createElement("label");
            label.htmlFor = this._name;
            label.innerHTML = `${description}: <em>*</em>`;
            guiField.appendChild(label);
        }

        guiField.appendChild(guiInput);

        return guiField;
    }

    set section(section) {
        this._section = section;
    }

    get section() {
        return this._section;
    }
}
